/* TAILWIND IMPORTS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* MAIN FONT */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@layer base {

	#hero {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 100vw;
	}

}